/*
 *  Copyleft 2017 by Philipp Giebel (info@ambnet.biz)
 *  Released under GPLv3: http://www.gnu.org/copyleft/gpl.html
 * 
 */

$(document).ready(function() {
  console.log('!!!');
  if ( $('body').hasClass('admin') ) {
    tinymce.init({
      selector: '#content',
      plugins : 'advlist autolink link image lists',
      menubar: false,
      height: 400
    });
    tinymce.init({
      selector: '.content.update',
      plugins : 'advlist autolink link image lists',
      branding: false,
      statusbar: false,
      menubar: false
    });
    
    $('.datetimepicker').datetimepicker({
      format: 'YYYY-MM-DD HH:mm',
      showTodayButton: true,
      allowInputToggle: true
    });

    $('.toggle-deletenews').on( 'change', function() {
      if ( $(this).prop('checked') == true ) {
        $( '.btn-deletenews'+ $(this).data('num') ).attr( 'href', 'admin.php?action=delete&id='+$( this ).data('id')+'&open='+$( this ).data('open')+'&closed='+$( this ).data('closed')  );
        $( '.btn-deletenews'+ $(this).data('num') ).removeClass( 'btn-disabled' );
      } else {
        $( '.btn-deletenews'+$( this ).data('num') ).attr( 'href', '#' );
        $( '.btn-deletenews'+ $(this).data('num') ).addClass( 'btn-disabled' );
      }
    });

    $('.selectpicker').selectpicker();
    $('input[data-toggle="toggle"]').bootstrapToggle();

  } else {
    $('.news').infiniteScroll({
      path: '.pagination .next',
      append: '.news .card',
      status: '.scroller-status',
      hideNav: '.pagination'
    });
  }
  $('*[title]').tooltip();
});